import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { FeasibilityStudyState } from './store';
import { UserActions } from './store/user/user.actions';

@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  constructor(private store: Store<FeasibilityStudyState>) {}

  ngOnInit(): void {
    this.store.dispatch(UserActions.checkUser());
  }
}
